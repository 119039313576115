<template>
  <div class="pipe">
    <div class="pipe-box">
      <div class="pipe-item pepe-left">
        <div class="btns">
          <el-button type="primary" :disabled="isDisabled"  size="small" icon="el-icon-plus" @click="onAdd(1)">新增</el-button>
          <span style="margin-left:10px">未配置默认:<span style="display:inline-block;width:15px;height:15px;background:#00d7ff;margin-left:5px"></span></span>
        </div>
        <el-table
          :data="steelTableData"
          :header-cell-style="{ background: '#E5E9EE' }"
          border
          style="width: 100%;height:calc(100vh - 300px);overflow: auto;"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictNames" align="center" label="镀锌管">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.dictName }}</div>
              <div v-else>
                <el-select
                  v-if="!row.id"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in steeloptions"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="row.isEditor"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in steeloptionsAlter"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode.toString()"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="showName" align="center" label="显示名称">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.showName }}</div>
              <div v-else>
                <el-input v-model="row.showName" placeholder="请输入名称"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="colour" align="center" width="100" label="显示颜色">
            <template #default="{ row }">
              <el-color-picker v-model="row.colour" :disabled="isEditor(row)"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column align="center" width="160" label="操作">
            <template #default="{ row, $index }">
              <div v-if="row.id && !row.isEditor" class="tableBtn">
                <span class="btn" style="color: #1e9fff" @click="steelEditor(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getDelete(row, 1)"> 删除 </span>
                <span
                  :class="[$index == 0 ? 'disabled' : 'up', 'btn']"
                  @click="upData($index, steelTableData)"
                >
                  上移
                </span>
                <span
                  :class="[isDown($index, steelTableData) ? 'disabled' : 'down', 'btn']"
                  @click="downData($index, steelTableData)"
                >
                  下移
                </span>
              </div>
              <div v-else>
                <el-button
                  v-if="!row.id"
                  size="mini"
                  type="text"
            
                  @click="onSave(row, $index, 1)"
                  >保存</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  type="primary"
                  @click="update(row, $index, 1)"
                  >保存</el-button
                >
                <el-button size="mini" type="text" style="color:#000" @click="steelCancel(row, $index)">取消</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pipe-item pepe-right" style="margin-right:10px">
        <div class="btns">
          <el-button type="primary" :disabled="isdxDisabled"  size="small" icon="el-icon-plus"  @click="onAdd(3)">新增</el-button>
          <span style="margin-left:10px">未配置默认:<span style="display:inline-block;width:15px;height:15px;background:#00d7ff;margin-left:5px"></span></span>
        </div>
        <el-table
          :data="dxTableData"
          :header-cell-style="{ background: '#E5E9EE' }"
          border
          style="width: 100%;height:calc(100vh - 300px);overflow: auto;"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictNames" align="center" label="钢管">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.dictName }}</div>
              <div v-else>
                <el-select
                  v-if="!row.id"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dxptions"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="row.isEditor"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dxptionsAlter"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="showName" align="center" label="显示名称">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.showName }}</div>
              <div v-else>
                <el-input v-model="row.showName" placeholder="请输入名称"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="colour" align="center" width="100" label="显示颜色">
            <template #default="{ row }">
              <el-color-picker v-model="row.colour" :disabled="isEditor(row)"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column align="center" width="160" label="操作">
            <template #default="{ row, $index }">
              <div v-if="row.id && !row.isEditor" class="tableBtn">
                <span class="btn" style="color: #1e9fff" @click="dxEditor(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getDelete(row, 3)"> 删除 </span>
                <span
                  :class="[$index == 0 ? 'disabled' : 'up', 'btn']"
                  @click="upData($index, peTableData)"
                >
                  上移
                </span>
                <span
                  :class="[isDown($index, peTableData) ? 'disabled' : 'down', 'btn']"
                  @click="downData($index, peTableData)"
                >
                  下移
                </span>
              </div>
              <div v-else>
                <el-button
                  v-if="!row.id"
                  size="mini"
                  type="primary"
                  @click="onSave(row, $index, 3)"
                  >保存</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  type="primary"
                  @click="update(row, $index, 3)"
                  >保存</el-button
                >
                <el-button size="mini" @click="dxCancel(row, $index)">取消</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pipe-item pepe-right">
        <div class="btns">
          <el-button type="primary" :disabled="isPeDisabled"  size="small" icon="el-icon-plus"  @click="onAdd(2)">新增</el-button>
          <span style="margin-left:10px">未配置默认:<span style="display:inline-block;width:15px;height:15px;background:#00d7ff;margin-left:5px"></span></span>
        </div>
        <el-table
          :data="peTableData"
          :header-cell-style="{ background: '#E5E9EE' }"
          border
          style="width: 100%;height:calc(100vh - 300px);overflow: auto;"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictNames" align="center" label="PE管">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.dictName }}</div>
              <div v-else>
                <el-select
                  v-if="!row.id"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in peptions"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="row.isEditor"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in peptionsAlter"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="showName" align="center" label="显示名称">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.showName }}</div>
              <div v-else>
                <el-input v-model="row.showName" placeholder="请输入名称"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="colour" align="center" width="100" label="显示颜色">
            <template #default="{ row }">
              <el-color-picker v-model="row.colour" :disabled="isEditor(row)"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column align="center" width="160" label="操作">
            <template #default="{ row, $index }">
              <div v-if="row.id && !row.isEditor" class="tableBtn">
                <span class="btn" style="color: #1e9fff" @click="peEditor(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getDelete(row, 2)"> 删除 </span>
                <span
                  :class="[$index == 0 ? 'disabled' : 'up', 'btn']"
                  @click="upData($index, peTableData)"
                >
                  上移
                </span>
                <span
                  :class="[isDown($index, peTableData) ? 'disabled' : 'down', 'btn']"
                  @click="downData($index, peTableData)"
                >
                  下移
                </span>
              </div>
              <div v-else>
                <el-button
                  v-if="!row.id"
                  size="mini"
                  type="primary"
                  @click="onSave(row, $index, 2)"
                  >保存</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  type="primary"
                  @click="update(row, $index, 2)"
                  >保存</el-button
                >
                <el-button size="mini" @click="peCancel(row, $index)">取消</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Try, Confirm } from '@/decorator'
import SetBase from '../apis/setBase'
export default {
  data() {
    return {
      steelTableData: [],
      peTableData: [],
      dxTableData:[],
      steeloptions: [],
      steeloptionsAlter: [],
      peptions: [],
      dxptions:[],
      peptionsAlter: [],
      dxptionsAlter: [],
      copyRow: new Map(),
      peCopyRow: new Map(),
      dxCopyRow: new Map(),
      deflatcolour:'deflatcolour'
    }
  },
  computed: {
    isDisabled() {
      if (!this.steelTableData.length) return false
      let list = this.steelTableData.filter(el => el.id)
      return list.length !== this.steelTableData.length || !this.steeloptions.length
    },
    isPeDisabled() {
      if (!this.peTableData.length) return false
      let list = this.peTableData.filter(el => el.id)
      return list.length !== this.peTableData.length || !this.peptions.length
    },
    isdxDisabled() {
      if (!this.dxTableData.length){return false;}
      let list = this.dxTableData.filter(el => el.id)
      return list.length !== this.dxTableData.length || !this.dxptions.length
    }
  },
  mounted() {
    this.getDictionaries()
    this.getList()
    this.getPeDictionaries()
    this.getPeList()
    this.getdxDictionaries()
    this.getdxList()
    
  },
  methods: {
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    //上移
    upData(index, tableData) {
      if (index == 0) return
      tableData = this.swapItems(tableData, index, index - 1)
      this.setSort(tableData)
    },

    //下移
    downData(index, tableData) {
      if (this.isDown(index, tableData)) return
      tableData = this.swapItems(tableData, index, index + 1)
      this.setSort(tableData)
    },
    
    async setSort(tableData) {
      let data = tableData.map((el, index) => {
        return {
          id: el.id,
          sort: index + 1
        }
      })
      let res = await SetBase.setSort(data)
    },
    /*
     *@description: 获取字典
     *@author: 黄丽玲
     */
   
    async getDictionaries() {
      let res = await SetBase.findNotSonList({ pidDictCode: '1000240001' })
      this.steeloptions = res.data
      // res.data.forEach(({ dictCode, dictName }) => this.optionsMap.set(dictCode, dictName))
    },
    
    async getList() {
      let res = await SetBase.getList({ pidDictCode: '1000240001' })
      this.steelTableData = res.data
    },
    
    async getPeDictionaries() {
      let res = await SetBase.findNotSonList({ pidDictCode: '1000240002' })
      this.peptions = res.data
      // res.data.forEach(({ dictCode, dictName }) => this.optionsMap.set(dictCode, dictName))
    },
    async getdxDictionaries() {
      let res = await SetBase.findNotSonList({ pidDictCode: '1000240003' })
      this.dxptions = res.data
      // res.data.forEach(({ dictCode, dictName }) => this.optionsMap.set(dictCode, dictName))
    },
  
    async getPeList() {
      let res = await SetBase.getList({ pidDictCode: '1000240002' })
      this.peTableData = res.data
    },
    async getdxList() {
      let res = await SetBase.getList({ pidDictCode: '1000240003' })
      this.dxTableData = res.data
    },
    isEditor(row) {
      return row.id != '' && row.id != null && !row.isEditor
    },
    isDown(index, tableData) {
      // let { steelTableData } = this
      let data = tableData.filter(el => el.id)
      return index == data.length - 1
    },
    // 钢管新增
    onAdd(type) {
      let obj = {
        dictCodes: '',
        showName: '',
        colour: '',
        id: null,
        pidDictCode: type == 1 ? '1000240001' : '1000240002',
        isEditor: true
      }
      if(type==3){
        obj.pidDictCode = '1000240003'
      }
       if(type==2){
        obj.pidDictCode = '1000240002'
      }
      if (type == 1) {
        this.steelTableData.push(obj)
      } else if(type==2) {
        this.peTableData.push(obj)
      }else{
        this.dxTableData.push(obj)
      }
    },
    // 钢管编辑
    async steelEditor(row) {
      console.log(row)
      let res = await SetBase.findNotSonList({ pidDictCode: '1000240001' })
      res.data.forEach(({ dictCode, dictName }) => {
        if (!this.copyRow.has(dictCode)) {
          this.copyRow.set(dictCode, dictName)
        }
      })
      let dictCodeA = row.dictCode.split(',');
      let dictNameA = row.dictName.split(',');
      this.steeloptionsAlter = res.data;
      dictCodeA.forEach((el,index)=>{
        if (!this.copyRow.has(el)) {
          this.copyRow.set(el,dictNameA[index])
        }
      
      this.steeloptionsAlter.push({dictCode:el,dictName:dictNameA[index]})
      })
      let data = JSON.parse(JSON.stringify(row))
      this.copyRow.set(data.id, data)
       this.$set(row, 'dictCodes', dictCodeA)
      row.dictCodes = dictCodeA
      this.$set(row, 'isEditor', true)
    },
    async peEditor(row) {
      let res = await SetBase.findNotSonList({ pidDictCode: '1000240002' })
       res.data.forEach(({ dictCode, dictName }) => {
        if (!this.peCopyRow.has(dictCode)) {
          this.peCopyRow.set(dictCode, dictName)
        }
      })
      let dictCodeA = row.dictCode.split(',');
      let dictNameA = row.dictName.split(',');
      this.peptionsAlter = res.data;
      dictCodeA.forEach((el,index)=>{
        if (!this.peCopyRow.has(el)) {
          this.peCopyRow.set(el,dictNameA[index])
        }
      this.peptionsAlter.push({dictCode:el,dictName:dictNameA[index]})
      })
      let data = JSON.parse(JSON.stringify(row))
      this.peCopyRow.set(data.id, data)
      this.$set(row, 'dictCodes', dictCodeA)
      row.dictCodes = dictCodeA
      this.$set(row, 'isEditor', true)
    },
    async dxEditor(row) {
      let res = await SetBase.findNotSonList({ pidDictCode: '1000240003' })
       res.data.forEach(({ dictCode, dictName }) => {
        if (!this.dxCopyRow.has(dictCode)) {
          this.dxCopyRow.set(dictCode, dictName)
        }
      })
      let dictCodeA = row.dictCode.split(',');
      let dictNameA = row.dictName.split(',');
      this.dxptionsAlter = res.data;
      dictCodeA.forEach((el,index)=>{
        if (!this.dxCopyRow.has(el)) {
          this.dxCopyRow.set(el,dictNameA[index])
        }
      this.dxptionsAlter.push({dictCode:el,dictName:dictNameA[index]})
      })
      let data = JSON.parse(JSON.stringify(row))
      this.dxCopyRow.set(data.id, data)
      this.$set(row, 'dictCodes', dictCodeA)
      row.dictCodes = dictCodeA
      this.$set(row, 'isEditor', true)
    },
    steelCancel(row, index) {
      if (row.id) {
        let { copyRow } = this
        let res = copyRow.get(row.id)
        row.colour = res.colour
        row.isEditor = res.isEditor
        row.showName = res.showName
        row.dictCodes = res.dictCodes
        this.copyRow.delete(row.id)
      } else {
        this.steelTableData.splice(index, 1)
      }
    },
    peCancel(row, index) {
      if (row.id) {
        let { peCopyRow } = this
        let res = peCopyRow.get(row.id)
        row.colour = res.colour
        row.isEditor = res.isEditor
        row.showName = res.showName
        row.dictCodes = res.dictCodes
        this.peCopyRow.delete(row.id)
      } else {
        this.peTableData.splice(index, 1)
      }
    },
    dxCancel(row, index) {
      if (row.id) {
        let { exCopyRow } = this
        let res = dxCopyRow.get(row.id)
        row.colour = res.colour
        row.isEditor = res.isEditor
        row.showName = res.showName
        row.dictCodes = res.dictCodes
        this.dxCopyRow.delete(row.id)
      } else {
        this.dxTableData.splice(index, 1)
      }
    },
    
    async update(row, index, type) {
      if(!row.colour){
        this.$message.error('请选择颜色')
        return
      }
      let data = {}
      data.colour = row.colour
      data.dictCodes = row.dictCodes.toString()
      data.id = row.id
      data.pidDictCode = row.pidDictCode
      data.showName = row.showName
      row.isEditor = false
      let res = await SetBase.update(data)
      if (type == 1) {
        this.getDictionaries()
        this.getList()
      } else if(type==2) {
        this.getPeDictionaries()
        this.getPeList()
      }else{
        this.getdxDictionaries()
        this.getdxList()
      }
    },
   
    async onSave(row, index, type) {
      if(!row.colour){
        this.$message.error('请选择颜色')
        return
      }
      let data = {}
      data.colour = row.colour
      data.dictCodes = row.dictCodes.toString()
      data.pidDictCode = row.pidDictCode
      data.showName = row.showName
      let res = await SetBase.addSave(data)
      if (type == 1) {
        this.getDictionaries()
        this.getList()
      } else if(type==2) {
        this.getPeDictionaries()
        this.getPeList()
      }else{
        this.getdxDictionaries()
        this.getdxList()
      }
      row.isEditor = false
    },
    
    async getDelete(row, type) {
      let res = await SetBase.getDelete({ id: row.id })
      if (type == 1) {
        this.getDictionaries()
        this.getList()
      } else if(type==2) {
        this.getPeDictionaries()
        this.getPeList()
      }else{
        this.getdxDictionaries()
        this.getdxList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pipe-box {
  display: flex;
  .btns {
    margin-bottom: 10px;
  }
  .pipe-item {
    flex: 1;
  }
  .pepe-left {
    margin-right: 10px;
  }
  .tableBtn {
    .btn {
      cursor: pointer;
      margin: 0 2px;
      &.up {
        color: #1e9fff;
      }
      &.down {
        color: #0dba7b;
      }
    }
  }
}
</style>
